<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-subheader>Pdfs Cargados por categorias</v-subheader>

          <v-data-table
            :headers="headers"
            :items="DataTemplates"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Listado de pdfs</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialogDataTemplate"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="newTemplate"
                    >
                      Nuevo pdf
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialogDataTemplate = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Cargar mapa</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark text @click="sendTable()"> Guardar </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-stepper v-model="stepperDataTemplate" vertical>
                      <v-stepper-step
                        :complete="stepperDataTemplate > 1"
                        step="1"
                        editable
                      >
                        Información mapa
                      </v-stepper-step>

                      <v-stepper-content step="1">
                        <v-card
                          color="cyan lighten-5"
                          class="mb-12"
                          height="100px"
                        >
                          <v-form>
                            <v-container>
                              <v-row>
                                <v-col cols="6">
                                  <v-text-field
                                    label="Titulo de la Pdf"
                                    required
                                    v-model="dataTemplate.name"
                                    id="nombre-planilla"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-select
                                    :items="Clients"
                                    label="Tag del segmento al que corresponde"
                                    v-model="dataTemplate.client_id"
                                    id="cliente-id"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    label="Descripción del pdf"
                                    required
                                    v-model="dataTemplate.description"
                                    id="descripcion-planilla"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-card>
                      </v-stepper-content>

                      <v-stepper-step
                        :complete="stepperDataTemplate > 2"
                        step="2"
                        editable
                      >
                        Configuración datos de entrada
                      </v-stepper-step>

                      <v-stepper-content step="2">
                        <v-card color="cyan lighten-5" class="mb-12">
                          <v-form>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-file-input
                                    accept="pdf/*"
                                    v-model="dataTemplate.pdf"
                                    @change="onDimensionImage"
                                    prepend-icon="mdi-pencil"
                                    label="Pdf para cargar"
                                    >
                                  </v-file-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editTemplate">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="viewMap(item.id)">
                mdi-card-bulleted-settings
              </v-icon>
            </template>
            <template v-slot:no-data> No se encontraron registros </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
export default {
  data() {
    return {
      chartData: {},
      finalprueba: [],
      dimensions: [],
      architectures: [],
      dataSend: [],
      loader: null,
      loading: false,
      btnDisable: true,
      tables: [],
      dialogDataTemplate: false,
      dataTemplate: {
        id: 0,
        name: "",
        client_id: "",
        pdf: "",
        description: "",
      },
      headers: [
        {
          text: "Titulo pdf",
          align: "start",
          value: "name",
        },
        { text: "Cliente", value: "client" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      DataTemplates: [],
      stepperDataTemplate: 1,
      dataType: {
        id: 0,
        name_field: "",
        type_field: "",
      },
      dataTypes: [
        {
          text: "Entero",
          value: "int",
        },
        {
          text: "Real",
          value: "float",
        },
        {
          text: "Cadena",
          value: "string",
        },
        {
          text: "Texto",
          value: "text",
        },
        {
          text: "Fecha",
          value: "date",
        },
      ],
      Fields: [
        {
          name_field: "Nombre",
          type_field: "string",
          orden: 1,
        },
        {
          name_field: "Edad",
          type_field: "int",
          orden: 2,
        },
        {
          name_field: "Ciudad",
          type_field: "string",
          orden: 3,
        },
      ],
      Clients: [],
      Types: [],
    };
  },
  computed: {
    
  },

  mixins:[misMixins],

  methods: {
    onDimensionImage(e) {
      var files = e;
      this.routeImg = URL.createObjectURL(files);
      return this.createPrincipalImage(files);
    },
    createPrincipalImage(file) {
      var reader = new FileReader();
      var vm = this;
      reader.readAsDataURL(file);
      reader.onload = function () {
        vm.dataTemplate.pdf = reader.result;
      };
    },
    viewMap(id) {
      console.log("hice clkick");
      this.$router.push({ path: "ShowMaps/" + id });
    },
    sendTable() {
      this.LoadingOpen();

      const architecture_tag_id = this.dataTemplate.client_id;
      const title = this.dataTemplate.name;
      const pdf = this.dataTemplate.pdf;
      const description = this.dataTemplate.description;
      const obj = {
          data:{
              attributes: {
                architecture_tag_id: architecture_tag_id,
                title: title,
                description: description,
                pdf: pdf,
              },
          }
      }
        console.log("el pdf1::::", obj);

      this.$http
        .post(
          "pdf_categories",
          {
            data: {
              attributes: {
                architecture_tag_id: architecture_tag_id,
                title: title,
                description: description,
                pdf: pdf,
              },
            },
            //})
          },
          { headers: { Authorization: "Bearer " + this.getCrypto() } }
        )
        .then((response) => {
          this.LoadingClose();

                        this.SucessAlert(
                        'Creado',
                        'datos importados con éxito'
                        )
        }).catch(error => {
                        this.LoadingClose();
                        this.ErrorAlert('Error al importar datos')
                    })
      //console.log('El Objeto en arreglo ',obj)

      this.dialogDataTemplate = false;
      this.$router.push({ name: "PdfTemplate" });
    },

    mounted() {
      this.renderChart(data, options);
    },
    newTemplate() {
      //console.log('New Template');
    },
    editTemplate() {},
    getData() {},
    addDataType() {
      this.dataTemplate.fields.push({
        id: 0,
        name_field: this.dataType.name_field,
        type_field: this.dataType.type_field,
        orden: 1,
      });

      this.dataType = {
        id: 0,
        name_field: "",
        type_field: "",
      };
    },
    getDataType(type) {
      var typeData = "";
      switch (type) {
        case "string":
          typeData = "Cadena";
          break;
        case "int":
          typeData = "Entero";
          break;
      }
      return typeData;
    },

    async getSegments() {
      console.log("las dimensiones", this.dimensions);
      for (var j = 0; j < this.dimensions.length; j++) {
        this.$http
          .get("architectures/simple/" + this.dimensions[j].id)
          //this.$http.get('architecture_tags/townhall/'+this.getClientSelect().id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
          .then((response) => {
            for (var i = 0; i < response.data.length; i++) {
              for (
                var k = 0;
                k < response.data[i].architecture_tag.length;
                k++
              ) {
                console.log(response.data[i].architecture_tag[k]);
                const value = response.data[i].architecture_tag[k].id;
                const text = response.data[i].architecture_tag[k].tag;
                const obj = {
                  value: value,
                  text: text,
                };
                this.Clients.push(obj);
                this.getCategories(value);
              }
            }
          });
      }
    },

    async getCategories(id) {
      try {
        const segment = await this.$http.get("architecture_tags/" + id);
        this.finalprueba.push(segment.data);
        console.log("final", this.finalprueba);
        if (segment.data.pdf_category.length > 0) {
          for (var i = 0; i < segment.data.pdf_category.length; i++) {
            const obj = {
              name: segment.data.pdf_category[i].title,
              client: this.getClientSelect().name,
              id: segment.data.pdf_category[i].id,
            };
            console.log("el pdf::::", obj);
            this.DataTemplates.push(obj);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    getDimension() {
      this.$http
        .get("dimensions/townhall/" + this.getClientSelect().id)
        //this.$http.get('dimensions/townhall/'+this.getClientSelect().id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
        .then((response) => {
          this.dimensions = response.body;
          console.log("dimensiones en mapas", this.dimensions);
          this.getSegments();
        })
        .catch((error) => {
          console.log("Error al consultar las dimensiones", error);
        });
    },
  },
  beforeMount() {
    this.getDimension();
  },
};
</script>